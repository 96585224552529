<script>
export default {
  name: 'Dropdownlink',
  components: {
    Icon: () => import('@/components/general/Icon')
  },
  props: {
    icon: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    url: {
      type: [String, Object],
      default: '#'
    },
    active: {
      type: Boolean,
      default: false
    },
    download: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    triggerClickEvent (event) {
      this.url === '#' && event.preventDefault()
      if (!this.disabled) {
        this.$parent.toggleMenu()
        this.$emit('click')
      }
    }
  }
}
</script>

<template>
  <a
    :href="url"
    class="dropdown-content-link"
    :class="{'is-disabled': disabled, 'is-active': active}"
    :download="download"
    @click="triggerClickEvent"
  >
    <icon
      v-if="icon"
      :name="icon"
      wrapper
      size="small"
    />
    <span class="text">{{ text }}</span>
  </a>
</template>

<style src="@/assets/styles/themes/default/dropdown.css"></style>
